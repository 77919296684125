header {
	width: 100%;
	height: 88px;
	background: rgb(255, 77, 3);
	display: flex;
	align-items: center;
}

header img {
	width: 150px;
	margin: auto;
}


header.white {
	background: white;

}

header.white img {
	width: 250px;
	margin: auto;
}


.App {
  padding-top: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Optima, sans-serif;
}

.grecaptcha-badge {
	display: none !important;
}

.centered {
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}


p {
	font-family: 'PT Serif', serif;
	max-width: 300px;
	width: 300px;
	margin-bottom: 0.5rem;
}

a {
	text-decoration: none;
}



.qrcode {
	margin: auto;
	font-weight: bold;
	font-size: 2rem;
	text-align: center;
	display: block;
}



button {
  width: 200px;
  padding: 16px;
  border: none;
  background-color: black;
  color: white;
  font-size: 1.2rem;
  font-family: 'PT Serif', serif;
  cursor: pointer;
  margin-top: 1em;
}

.redirecting {
  align-self: center;
  width: 100px;
}

.redirecting:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 2000ms infinite;      
  animation: ellipsis steps(4,end) 2000ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}